@import '@/components/variables.scss';

.pill {
  
  flex: 0 0 auto;
  position: relative;
  border-radius: $x-field-border-radius;
  min-height: 32px;
  min-width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: spacer(sm);
  padding-right: spacer(sm);
  border: 1px solid rgba(0,0,0,0.05);
  font-size: .875rem;
  
  svg {
    height: 12px;
    width: 12px;
  }
  
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: spacer(xs);
  }
  
}

.primary {
  background-color: var(--primaryColor);
  color: var(--primaryText);
  button {
    color: var(--primaryText);
  }
}
.primary-faint {
  background-color: var(--primaryColorFaint);
  color: var(--primaryColor);
  button {
    color: var(--primaryColor);
  }
}
.secondary {
  background-color: var(--secondaryColor);
  color: var(--secondaryText);
  button {
    color: var(--secondaryText);
  }
}
.white {
  background-color: white;
  color: var(--primaryColor);
  button {
    color: var(--primaryColor);
  }
}
.black {
  background-color: black;
  color: var(--primaryText);
  button {
    color: var(--primaryText);
  }
}
.red {
  background-color: var(--redColor);
  color: white;
  button {
    color: var(--primaryText);
  }
}
.full {
  width: 100%;
}
