@import '@/components/variables.scss';

.b {
  display: block;
  position: relative;
  width: 100%;
}

.scb {
  display: block;
  position: absolute;
  top: 0;
  &l {
    left: 0;
    button {
      border-width: 0 1px 0 0;
    }
  }
  &r {
    right: 0;
    button {
      border-width: 0 0 0 1px;
    }
  }
}

.c {
  display: flex;
  width: auto;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;

  &:first-child {
    padding-top: 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  

  &scroll {
    @media screen and (max-width: $x-breakpoint-xs) {
      overflow: hidden;
      overflow-x: auto;
    }
  }
  
  &d {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
  }

  &sb {
    padding-bottom: spacer(base);
  }

  &sp {
    padding-top: spacer(base);
  }
  
}

.l {

  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: spacer(base);
  box-sizing: border-box;
  align-items: center;
  position: relative;
  
  padding-left: spacer(lg);
  padding-right: spacer(lg);
  
  @media screen and (max-width: $x-breakpoint-xs) {
    padding-left: spacer(base);
    padding-right: spacer(base);
  }

  &_ws {
    width: 100%;
  }

  &:first-child {
    margin-left: auto;
  }
  
  &:last-child {
    margin-right: auto;
  }
  
  &c {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: spacer(base);
    box-sizing: border-box;
    align-items: center;
    position: relative;
  }
  
  > div {
    flex: 0 0 auto;
    position: relative;
  }
  
  &_search {

    flex: 1 1 auto !important;
    position: relative;
    
    input {
      border-radius: var(--buttonBorderRadius) !important;
      padding-left: calc(44px) !important;
    }
      
    i {
      display: block !important;
      position: absolute;
      top: calc(22px - 12px);
      left: calc(12px);
      color: var(--grayColor);
      svg {
        width: 24px;
        height: 24px;
      }
    }
    
  }

  &_sort {
    display: block !important;
    @media screen and (max-width: $x-breakpoint-xs) {
      padding-right: spacer(base);
      select {
        min-width: 180px;
      }
    }
  }

  select {
    border-radius: var(--buttonBorderRadius) !important;
    width: 160px;
    padding-left: 18px;
  }
  
}

.fpg {
  display: flex;
  gap: spacer(base);
}

.bar {
  padding-top: spacer(base);
  padding-bottom: spacer(base);
}

.cls {
  
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: spacer(base);
  min-width: calc((64px * 5) + (spacer(base) * 4));
  max-width: 100vw;

  @media screen and (max-width: $x-breakpoint-xs) {
    min-width: calc(100% - (spacer(base) * 4));
  }
  
  &lbl {
    display: block;
    text-align: center;
    padding-top: spacer(xs);
    font-size: .675rem;
    color: var(--grayColor);
  }
  
  > div {
    width: 64px;
    @media screen and (max-width: $x-breakpoint-xs) {
      width: calc((100% - (spacer(base) * 3)) / 4);
    }
  }
  
  button {
    width: 100%;
  }

  &sel {
    border-color: white;
    box-shadow: 0 0 0 2px var(--primaryColor);
  }
  
}

.br {

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: spacer(xs);
  min-width: calc((154px * 3) + (spacer(base) * 2));
  max-width: 100vw;
  
  @media screen and (max-width: $x-breakpoint-xs) {
    min-width: 0;
    width: 100%;
  }

  &i {
    min-width: 154px;
    @media screen and (max-width: $x-breakpoint-xs) {
      min-width: calc(50% - spacer(xs));
    }
  }
  
}

.mlg {
  
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: spacer(base);
  min-width: 200px;
  max-width: 100vw;
  
}

.fltc {
  
  display: block;
  border-radius: $x-tile-border-radius;
  padding: spacer(xl);
  background-color: rgba(255,255,255,0.98);
  margin-left: spacer(lg);
  margin-right: spacer(lg);

  @media screen and (max-width: $x-breakpoint-xs) {
    margin-left: 0;
    margin-right: 0;
    padding: spacer(lg);
  }
  
}

.fltp {
  
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  gap: spacer(xl);

  @media screen and (max-width: $x-breakpoint-lg) {
    flex-direction: column;
    gap: spacer(lg);
  }

}

.fltsdw {
  box-shadow: $x-base-shadow;
}

.pmt {
  
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  &P {
    
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: spacer(base);
    padding-bottom: spacer(xl);
    
    @media screen and (max-width: $x-breakpoint-xs) {
      flex-direction: column;
      padding-bottom: spacer(lg);
    }
    
    &D3 {
      flex: 0 0 auto;
      width: calc(33% - (spacer(base)/3));
      @media screen and (max-width: $x-breakpoint-xs) {
        width: 100%;
      }
    }
    
    &C {
      display: flex;
      height: 44px;
      flex-direction: row;
      background-color: var(--primaryColorFaint);
      border-radius: $x-field-border-radius;
      width: 100%;
      justify-content: center;
      margin-bottom: spacer(xs);
      
      label {
        flex: 1 1 auto;
        font-size: .875rem;
        font-weight: 700;
        padding-left: spacer(base);
        line-height: 44px;
      }
      
      span {
        flex: 0 0 auto;
        font-size: 1rem;
        font-weight: 700;
        padding-right: spacer(base);
        color: var(--primaryColor);
        line-height: 44px;
      }
      
    }
    
  }
  
  &P2 {
    
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: spacer(xl);
    
    @media screen and (max-width: $x-breakpoint-xs) {
      flex-direction: column;
      gap: spacer(base);
    }

    &D2 {
      flex: 0 0 auto;
      width: calc(50% - (spacer(xl)/2));
      font-size: .875rem;
      font-weight: 700;
      @media screen and (max-width: $x-breakpoint-xs) {
        width: 100%;
      }
    }
        
  }
  
}

.sum {
  
  flex: 0 0 auto;
  background-color: var(--primaryColor);
  padding: spacer(xl);
  border-radius: $x-tile-border-radius;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: $x-breakpoint-lg) {
    flex-direction: row;
    gap: spacer(base);
    padding: spacer(lg);
    flex-wrap: wrap;
  }
  
  &L {
    flex: 0 0 auto;
    font-size: .875rem;
    color: white;
    padding-bottom: spacer(xs);
    @media screen and (max-width: $x-breakpoint-lg) {
      padding-bottom: 0;
    }
  }
  &P {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: spacer(base);
    @media screen and (max-width: $x-breakpoint-lg) {
      padding-bottom: 0;
    }
    span {
      flex: 0 0 auto;
      &:first-child {
        color: var(--primaryText);
        font-size: 1rem;
      }
      &:last-child {
        color: white;
        font-size: 2.5rem;
      }
    }
  }
  &B {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: spacer(xs);

    @media screen and (max-width: $x-breakpoint-xl) {
      flex-direction: column;
    }

    @media screen and (max-width: $x-breakpoint-lg) {
      flex-direction: row;
    }
      
    @media screen and (max-width: $x-breakpoint-xs) {
      flex-direction: column;
    }
    
    > div {
      flex: 0 0 auto;
    }
  }
  
}

.stoggle {
  display: block;
  padding: 4px 0;
  height: 44px;
  > div {
    display: flex;
    flex-direction: row;
    border-radius: $x-field-border-radius;
    background-color: var(--primaryColor);
    height: 36px;
    padding: 2px;
    a {
      flex: 0 0 auto;
      color: var(--primaryText);
      line-height: 32px;
      padding: 0 8px;
      border-radius: calc($x-field-border-radius - 2px);
    }
  }
  &_active {
    background-color: var(--primaryText) !important;
    color: var(--primaryColor) !important;
  }
}
