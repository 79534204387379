@import '@/components/variables.scss';

.base {
  background-color: var(--primaryColor);
  color: var(--primaryText);
  font-weight: 700;
  font-size: 1rem;
  padding-left: spacer(md);
  padding-right: spacer(md);
  display: flex;
  height: 44px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: var(--buttonBorderRadius);
  border: 1px solid transparent;
  width: 100%;
  position: relative;
  
  span {
    flex: 0 0 auto;
    + i {
      margin-left: spacer(xs)
    }
    + svg {
      margin-left: spacer(xs)
    }
  }
  
  &-rows {
    flex-direction: column;
  }
  
  i {
    + span {
      margin-left: spacer(xs)
    }
    svg {
      height: 16px;
    }
    
  }
  
  &:disabled {
    background-color: #DDDDDD;
    color: #999999;
  }
  
}

.badge {
  display: block;
  position: absolute;
  background-color: currentColor;
  width: 8px;
  height: 8px;
  top: 2px;
  right: 2px;
  border-radius: 100%;
}

.badge-text {
  display: block;
  position: absolute;
  background-color: var(--primaryColor);
  color: var(--primaryText) !important;
  height: 14px;
  font-size: 9px;
  line-height: 14px;
  padding-left: 2px !important;
  padding-right: 2px !important;
  top: 0px;
  right: 3px;
  border-radius: 9999999px;
  font-weight: 400 !important;
}
.badge-inline {
  position: relative;
  height: 20px;
  line-height: 20px;
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.primary {
  background-color: var(--primaryColor);
  color: var(--primaryText);
}
.secondary {
  background-color: var(--secondaryColor);
  color: var(--secondaryText);
}
.primary-faint {
  background-color: var(--primaryColorFaint);
  color: var(--primaryColor);
}
.primary-faint-hover {
  &:hover {
    background-color: var(--primaryColorFaint);
    color: var(--primaryColor);
  }
}
.red {
  background-color: var(--redColor);
  color: white !important;
}
.white {
  background-color: white;
  color: var(--primaryColor);
}
.gray-light {
  background-color: var(--lightGrayColor);
  color: var(--primaryColor);
}
.gray-light-hover {
  &:hover {
    background-color: var(--lightGrayColor);
    color: black;
  }
}

.transparent {
  background-color: transparent;
  color: black;
}

.nopad {
  padding-left: 0;
  padding-right: 0;
}
.plain {
  background-color: transparent;
  color: black;
  font-weight: 400;
  height: auto;
  padding-left: 0;
  padding-right: 0;
  svg {
    width: 24px;
  }
}
.none {
  background-color: transparent;
  color: inherit;
  font-weight: 400;
  height: auto;
  padding-left: 0;
  padding-right: 0;
  text-align: left;
  svg {
    width: 24px;
  }
}

.icon {
  display: flex;
  width: 44px;
  height: 44px;
  padding: 0 0 0 0;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--primaryColor);
  i {
    display: block !important;
    flex: 0 0 auto;
  }
  svg {
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
  }
  &.inline {
    width: 44px;
  }
}

.icon-primary {
  background-color: var(--primaryColor);
  color: var(--primaryText);
}
.icon-secondary {
  background-color: var(--secondaryColor);
  color: var(--secondaryText);
}

.icon-primary-faint {
  color: #FFFFFF;
  svg {
    flex: 0 0 auto;
  }
}

.icon-white {
  color: #FFFFFF;
  svg {
    flex: 0 0 auto;
  }
}

.icon-oem {
  width: 64px;
  svg {
    width: 80%;
    height: 70%;
  }
  img {
    max-width: 80%;
    max-height: 70%;
  }
}

.icon-oem-top {
  filter: var(--topOemIconColor);
}

.icon-oem-white {
  filter: invert(100%) sepia(10%) saturate(768%) hue-rotate(42deg) brightness(116%) contrast(100%)  
}

.icon-small {
  width: auto;
  height: auto;
  i {
    svg {
      width: 24px;
      height: 24px;
    }
  }
}
.icon-medium {
  width: auto;
  height: auto;
  i {
    svg {
      width: 36px;
      height: 36px;
    }
  }
}

.line {
  background-color: transparent;
  border-color: var(--primaryColor);
  color: var(--primaryColor);
  margin: 0 0 0 0;
}

.line-secondary {
  background-color: transparent;
  border-color: var(--secondaryColor);
  color: var(--secondaryColor);
  margin: 0 0 0 0;
}

.line-white {
  background-color: transparent;
  border-color: white;
  color: white;
  margin: 0 0 0 0;
}

.inline {
  display: inline-block;
  width: auto;
  line-height: 42px;
}

.inline-flex {
  width: auto;
  line-height: 42px;
}

.filter {
  background-color: var(--filterButtonBg);
  color: black;
  border-radius: $x-field-border-radius;
  font-weight: 700;
  font-size: .875rem;
}

.filter-icon {
  display: flex;
  flex-direction: column;
  width: 44px;
  height: 44px;
  padding: 0 0 0 0;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: black;
  border-radius: $x-field-border-radius;
  svg {
    flex: 0 0 auto;
    height: 24px;
    width: 36px;
  }
  span {
    flex: 0 0 auto;
    font-size: .675rem;
    color: black;
    margin-left: 0 !important;
    font-weight: 400;
  }
}

.filter-icon-wide {
  width: 64px;
}

.filter-on {
  background-color: var(--filterSelectedBg);
  color: var(--filterSelectedText);
  span {
    color: var(--filterSelectedText);
  }
  &:hover {
    background-color: var(--filterSelectedBg);
    color: var(--filterSelectedText);
    span {
      color: var(--filterSelectedText);
    }
  }
}

.filter-opt {
  font-weight: 400;
  font-size: .875rem;
}

.filter-scroll {
  background-color: var(--bodyBg);
  color: var(--primaryColor);
  border-radius: 0;
  border-color: var(--fieldBorderColor);
  border-style: solid;
}

.primary-text {
  color: var(--primaryColor);
}

.facebook {
  background-color: rgb(54,121,233);
  color: white;
  font-weight: 400;
  width: 100%;
  svg {
    width: 20px;
    height: 20px;
    flex: 0 0 auto;
    fill: #FFFFFF;
    stroke: #FFFFFF;
    margin-right: 8px;
  }
}
.google {
  background-color: white;
  border-color: #DDD;
  color: black;
  font-weight: 400;
  width: 100%;
  svg {
    width: 20px;
    height: 20px;
    flex: 0 0 auto;
    fill: #FFFFFF;
    stroke: #FFFFFF;
    margin-right: 8px;
  }
}

.photos {
  display: block;
}

.tile {
  background-color: var(--tileBackground);
  color: var(--tileText);
  min-height: 108px;
  box-sizing: border-box;
  height: 100%;
  padding-top: spacer(xl);
  padding-bottom: spacer(xl);
  border-radius: $x-tile-border-radius;
}

.floating {
  flex: 0 0 auto;
  min-width: 32px;
  height: 32px;
  width: auto;
  padding-left: spacer(sm);
  padding-right: spacer(sm);
  background-color: rgba(255,255,255,0.9);
  border-radius: $x-field-border-radius;
  color: var(--primaryColor);
  font-weight: 400;
  font-size: .875rem;
  svg {
    width: 16px;
    height: 16px;
  }
}

.semi-black {
  background-color: rgba(0,0,0,0.5);
  color: white;
}

.m {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  
  &i {
    flex: 1 1 auto;
    width: auto;
    background-color: white;
    color: black;
    border-radius: 0 0 0 0;
    
    &S {
      background-color: gray;
      color: white;
    }
    
    &:first-child {
      border-top-left-radius: var(--buttonBorderRadius);
      border-bottom-left-radius: var(--buttonBorderRadius);
    }
    &:last-child {
      border-top-right-radius: var(--buttonBorderRadius);
      border-bottom-right-radius: var(--buttonBorderRadius);
    }
    
  }
  
}