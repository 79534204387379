@import '@/components/variables.scss';

.bar {
  
  position: sticky;
  background-color: var(--bodyBg)
    
}

.top {
  top: -1px;
  z-index: 10;
}

.bottom {
  bottom: -1px;
  z-index: 10;
}