@import '@/components/variables.scss';

$x-inv-tile-spacer: spacer(lg);

.hero {
  display: block;
  position: relative;
  width: 100%;
  height: var(--heroHeight);  
}

.title {
  font-size: 1.2rem;
  font-weight: 700;
}

.bar {
  
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  
  &Cell {
    flex: 0 0 auto;
    padding-right: spacer(base);
    &:first-child {
      padding-left: $x-inv-tile-spacer;
    }
    &:last-child {
      padding-right: $x-inv-tile-spacer;
    }
  }
  
  &Stretch {
    flex: 1 1 auto;
  }
  
}

.tiles {
  
  display: block;
  padding: $x-inv-tile-spacer;
  
  &Inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $x-inv-tile-spacer;
    width: 100%;
    justify-content: center;
  }
  
  @media screen and (max-width: $x-breakpoint-xs) {
    padding: $x-inv-tile-spacer 0;
  }
  
}

.Ad {
    
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: $x-inv-tile-spacer;
  z-index: 1;
  
  &L {
    flex: 0 0 auto;
    padding-top: spacer(lg);
    color: white;
    max-width: 65%;
    line-height: 1.2rem;
    &:last-child {
      padding-bottom: spacer(lg);
    }
  }
  
  &M {
    flex: 1 1 auto;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
  
}

.fbar {
  
  padding-bottom: spacer(base) !important;

  @media screen and (max-width: $x-breakpoint-xs) {
    max-height: calc(100vh - var(--headerBarHeight));
  }
  &s {
    padding-top: spacer(base) !important;
    @media screen and (max-width: $x-breakpoint-xs) {
      padding-top: calc(var(--headerBarHeight) + spacer(base)) !important;
    }
  }
}

.sbar {
  display: block;
  padding-top: spacer(base);
  padding-left: spacer(xl);
  padding-right: spacer(xl);
  max-width: var(--contentMaxWidth);
  margin: auto;
  position: relative;
  input {
    border-color: transparent !important;
    border-radius: var(--buttonBorderRadius) !important;
    padding-left: calc(spacer(xl) + 22px) !important;
  }
  i {
    display: block !important;
    position: absolute;
    top: 10px;
    left: 12px;
    color: var(--primaryColor);
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.tooltip:before {
  content: attr(data-title);
  position:absolute;
  left:0;
  right: 0;
  bottom: 0;
  z-index: 5;
  padding: 0 spacer(xl);
  background: rgba(0,0,0,0.8);
  color: white;
  display: block;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.2rem;
  transition: all 0.5s ease;
  max-height: 0;
  box-sizing: content-box;
}
.tooltip:hover:before {
  padding: spacer(xl) spacer(xl);
  max-height: 100%;
}

.loadol {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &txt {
    display: block;
    background-color: #EEEEEE;
    margin-bottom: spacer(sm);
  }

  &::after {
    content: "";
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(0,0,0,0) 10%, rgba(255,255,255,0.7) 45%, rgba(255,212,255,0) 80%);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    animation: loadingAnimation 2s both;
    animation-iteration-count: infinite;
  }

}

@keyframes loadingAnimation {

  0% {
    left: -80%;
    right: 80%;
  }

  100% {
    left: 100%;
    right: -100%;
  }

}

.srp {
  padding-top: spacer(base);
}

.ta {
  display: block;
  padding: 0;
  margin: 0;
  > a {
    display: block;
    border-bottom: 1px solid var(--primaryColorFaint);
    background-color: white;
    padding: spacer(base);
    &:hover {
      background-color: var(--primaryColorFaint);
    }
  }
}