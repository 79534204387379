@import '@/components/variables.scss';


.cont {
  display: block;
}
.form {
  display: block;
  padding: spacer(lg);
}
.rows {
  display: flex;
  flex-direction: column;
  gap: spacer(base);
  > div {
    flex: 0 0 auto;
    min-width: 240px;
    max-width: calc(100vw - 2 * spacer(lg));
  }
}
.divider {
  display: block;
  flex: 0 0 auto;
  border-top: 1px solid #DDD;
}
.panel {
  @media screen and (min-width: $x-breakpoint-xs) {
    max-width: 640px;
  }
  @media screen and (max-width: $x-breakpoint-xs) {
    width: 100vw;
    padding-left: 0;
    padding-right: 0;
  }
}