@import '@/components/variables.scss';

.Panel {
  padding-left: spacer(xl);
  padding-right: spacer(xl);
  @media screen and (min-width: $x-breakpoint-xs) {
    width: 100%;
    max-width: 640px;
  }
  @media screen and (max-width: $x-breakpoint-xs) {
    width: 100%;
    max-width: 100vw;
    padding-left: 0;
    padding-right: 0;
  }
}
.LP {
  padding-left: spacer(xxl);
  padding-right: spacer(xxl);
  @media screen and (max-width: $x-breakpoint-xs) {
    padding-left: spacer(base);
    padding-right: spacer(base);
  }
}