@import '@/components/variables.scss';

.container {
  
  display: block;
  padding-left: spacer(xl);
  padding-top: spacer(xl);
  padding-bottom: spacer(xxl);
  position: relative;
  max-width: var(--contentMaxWidth);
  margin: auto;
  width: 100%;
  
}

.panel {
  
  display: block;
  width: 100%;
  height: 480px;
  border-radius: var(--tileBorderRadius);
  position: relative;
  overflow: hidden;
  
}

.backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.1);
}

.message {
  
  display: flex;
  flex-direction: column;
  position: absolute;
  top: spacer(xxl);
  bottom: spacer(xxl);
  left: 10%;
  right: 10%;
  
  > div {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 420px;
    div {
      flex: 0 0 auto;
      padding-bottom: spacer(xl);
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
  
  &-v-top {
    justify-content: flex-start;
  }
  
  &-v-bottom {
    justify-content: flex-end;
  }
  
  &-v-middle {
    justify-content: center;
  }
  
  &-v-top-bottom {
    height: calc(100% - 2*spacer(xxl));
    > div {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      > div {
        &:first-child {
          flex: 1 1 auto;
        }
        &:last-child {
          flex: 0 0 auto;
        }
      }
    }
  }
  
  &-h-left {
    align-items: flex-start;
  }
  
  &-h-right {
    align-items: flex-end;
  }
  
  &-h-center {
    align-items: center;
    text-align: center;
  }
}

.ad-text-large {
  font-size: 2.5rem;
  line-height: 2.7rem;
}
.ad-text-medium {
  font-size: 2.0rem;
  line-height: 2.2rem;
}
.ad-text-small {
  font-size: 1.4rem;
  line-height: 1.6rem;
}

.ad-text-bold {
  font-weight: 700;
}
.ad-text-regular {
  font-weight: 400;
}

.ad-text-color-primary {
  color: var(--primaryColor);
}
.ad-text-color-secondary {
  color: var(--secondaryColor);
}
.ad-text-color-white {
  color: white;
}
.ad-text-color-black {
  color: black;
}
