@import '@/components/variables.scss';
@import '../../components/form/ua.module.scss';

.P {
  
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  
  // @media screen and (max-width: $x-breakpoint-lg) {
  //   display: block;
  //   padding-top: 0;
  // }
  
  @media screen and (max-width: $x-breakpoint-sm) {
    display: block;
    padding-top: 0;
  }
  
  @media screen and (max-width: $x-breakpoint-xs) {
    padding-top: 0;
    display: block;
  }
  
  &M {
    flex: 0 0 auto;
    width: 60%;
    padding-left: spacer(xl);
    padding-right: spacer(xl);
    position: relative;
    
    // @media screen and (max-width: $x-breakpoint-xl) {
    //   display: block;
    //   padding-left: 0;
    //   padding-right: 0;
    // }
    
    @media screen and (max-width: $x-breakpoint-lg) {
      padding-right: spacer(base);
    }
    
    @media screen and (max-width: $x-breakpoint-sm) {
      display: block;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
    
    @media screen and (max-width: $x-breakpoint-xs) {
      display: block;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
  }
  
  &C {
    flex: 0 0 auto;
    width: 40%;
    
    @media screen and (min-width: $x-breakpoint-lg) {
      padding-left: spacer(base);
      padding-right: spacer(xl);
    }
    
    @media screen and (max-width: $x-breakpoint-sm) {
      display: block;
      width: 100%;
    }
    
    @media screen and (max-width: $x-breakpoint-xs) {
      display: block;
      width: 100%;
    }      
    
  }
  
}

.flbtns {
  
  display: block;
  position: absolute;
  
  top: spacer(base);
  right: calc(spacer(xl) + spacer(base));
  
  @media screen and (max-width: $x-breakpoint-lg) {
    top: spacer(base);
    right: calc(spacer(xl) + spacer(base));
  }
  
  @media screen and (max-width: $x-breakpoint-sm) {
    top: spacer(base);
    right: spacer(base);
  }
  
  @media screen and (max-width: $x-breakpoint-xs) {
    top: spacer(base);
    right: spacer(base);
  }
}

.sections {
  display: block;
  padding-top: spacer(lg);
  padding-bottom: spacer(lg);
  
  @media screen and (min-width: $x-breakpoint-lg) {
    padding-top: 0;
  }
  
}

.block {
  padding-top: spacer(lg);
  padding-bottom: spacer(lg) !important;
  margin-bottom: spacer(lg);
  border-radius: $x-tile-border-radius;
  
  @media screen and (max-width: $x-breakpoint-sm) {
    border-radius: 0;
  }
  
  @media screen and (max-width: $x-breakpoint-xs) {
    border-radius: 0;
  }          

}

.divider {

  &::before {
    content: "";
    display: block;
    border-top: 1px solid #DDD;
  }
  
}

.title {

  display: block;
  
  &Sm {
    
    display: block;
    font-size: 1rem;
    font-weight: 400;
    
    @media screen and (max-width: $x-breakpoint-lg) {
      font-size: .9rem;
    }
    
    @media screen and (max-width: $x-breakpoint-sm) {
      font-size: .9rem;
    }
    
    @media screen and (max-width: $x-breakpoint-xs) {
      font-size: .9rem;
    }      
    
  }
  &Md {
    
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.7rem;
    
    @media screen and (max-width: $x-breakpoint-lg) {
      font-size: 1rem;
      line-height: 1.1rem;
    }
    
    @media screen and (max-width: $x-breakpoint-sm) {
      font-size: 1rem;
      line-height: 1.1rem;
    }
    
    @media screen and (max-width: $x-breakpoint-xs) {
      font-size: 1rem;
      line-height: 1.1rem;
    }      
    
  }
  &Lg {
    
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 2.7rem;
    
    @media screen and (max-width: $x-breakpoint-lg) {
      font-size: 1.75rem;
      line-height: 2rem;
    }
    
    @media screen and (max-width: $x-breakpoint-sm) {
      font-size: 1.75rem;
      line-height: 2rem;
    }
    
    @media screen and (max-width: $x-breakpoint-xs) {
      font-size: 1.75rem;
      line-height: 2rem;
    }      
    
  }
}

.h {
  
  flex: 0 0 auto;
  display: block;
  color: var(--primaryColor);
  text-align: center;
  font-weight: 700;
  padding-top: spacer(base);
  padding-bottom: spacer(base);

  @media screen and (max-width: $x-breakpoint-sm) {
    text-align: center;
  }
  
}

.h2 {
  font-size: 2rem;
}

.h3 {
  font-size: 1.2rem;
}

.ctas {
  
  display: block;
  
  button {
    margin-bottom: spacer(base);
    &:last-child {
      margin-bottom: 0;
    }
  }

  &_panel {
    padding-left: spacer(xxl);
    padding-right: spacer(xxl);
    padding-top: spacer(base);
  }
  
}

.g {
  flex: 0 0 auto;
  width: 100%;
  padding: 37.5%;
  position: relative;
  border-radius: $x-tile-border-radius;
  overflow: hidden;

  // @media screen and (max-width: $x-breakpoint-lg) {
  //   border-radius: 0;
  // }
  
  @media screen and (max-width: $x-breakpoint-sm) {
    border-radius: 0;
  }
  
  @media screen and (max-width: $x-breakpoint-xs) {
    border-radius: 0;
  }      

}

.opts {
  
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: spacer(xl);
  
  @media screen and (min-width: $x-breakpoint-lg) {
    gap: spacer(xl);
  }
  
  &Hdr {
    display: block;
    padding-bottom: spacer(xs);
    color: black;
  }
  
  > div {
    
    flex: 1 1 auto;
    // width: calc(50% - (spacer(xl))/2);

    // @media screen and (min-width: $x-breakpoint-lg) {
    //   width: calc(50% - (spacer(xl))/2);
    // }
      
    > div {

      display: block;
      
      &:last-child {
        padding-bottom: 0;
        font-weight: 700;
        @media screen and (min-width: $x-breakpoint-lg) {
          font-size: 1.2rem;
        }
      }
      
    }
    
  }
  
}

.specs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: spacer(base);

  > div {
    flex: 0 0 auto;
    width: calc(33.333% - (spacer(base)));
    display: flex;
    flex-direction: row;
    gap: spacer(sm);
    @media screen and (max-width: $x-breakpoint-xs) {
      width: calc(50% - (spacer(base)/2));
    }
    > div {
      flex: 0 0 auto;
      &:first-child {
        // font-size: 24px;
        // line-height: 12px;
      }
      &:last-child {
        flex: 1 1 auto;
      }
    }
  }
}

.hilit {
  display: flex;
  background-color: white;
  border-radius: $x-tile-border-radius;
  width: auto;
  z-index: 1;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  padding: spacer(base);

  &::-webkit-scrollbar {
    display: none;
  }
  
  @media screen and (max-width: $x-breakpoint-xs) {
    overflow-y: auto;
    z-index: 10;
    margin-left: calc(-1*spacer(lg));
    margin-right: calc(-1*spacer(lg));
    border-radius: 0;
  }

  &d {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    gap: spacer(base);
    min-width: 100%;
    @media screen and (max-width: $x-breakpoint-xs) {
      gap: spacer(xl);
    }
  }

}

.pw {
  
  display: flex !important;
  flex-direction: column;
  gap: spacer(sm);
  
  > div {
    flex: 0 0 auto;
  }

  &sldr {
    display: block;
    padding-bottom: spacer(base);
  }
  
  &s {
    
    display: flex;
    flex-direction: row;
    
    &l {
      flex: 1 1 auto;
      font-size: .875rem;
      color: #000000;
      svg {
        font-size: .675rem;
      }
    }
    
    &v {
      flex: 0 0 auto;
      font-size: 1rem;
      font-weight: 700;
    }
    
  }

}


.sh {
  
  height: 0;
  overflow: hidden;
  padding: 0 0 0 0;
  
  &v {
    height: auto;
    padding-top: spacer(base);
    padding-bottom: spacer(base);
    border-bottom: 1px solid #DDD;
    @media screen and (max-width: $x-breakpoint-xs) {
      padding-top: calc(var(--headerBarHeight) + spacer(base)) !important;
    }
      
    span, h1 {
      font-size: .875rem !important;
      line-height: 1rem;
    }
    
  }
  
}

.ft_ico {
  font-size: 2.5rem;
  line-height: 24px;
  svg {
    max-width: 64px !important;
    height: 32px !important;
  }
}

.deli {

  &_img {
    width: 40%;
    position: relative;
    @media screen and (max-width: $x-breakpoint-xs) {
      width: 100%;
      min-height: 120px;
      padding-bottom: spacer(xl);
    }
  }

}

.spinner {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  color:rgba(39, 37, 37, 1);
}
.spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: 30%;
  left: 40%;
  width: 80px;
  height: 80px;
  margin: 6px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
  z-index: 1001;
}
.spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.spinner div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 2;
}