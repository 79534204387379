@import '@/components/variables.scss';

.bar {
  display: block;
  background-color: var(--headerBg);
  color: var(--headerText);
  &_i {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: var(--headerBarHeight);
    > div {
      flex: 0 0 auto;
      padding-right: spacer(base);
      color: var(--primaryColor);
      &:first-child {
        padding-left: spacer(base);
      }
      svg {
        width: 20px;
        height: 20px;
      }
      @media screen and (max-width: $x-breakpoint-xs) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    &_s {
      flex: 1 1 auto !important;
    }
  }
  
  @media screen and (max-width: $x-breakpoint-xs) {
    position: sticky;
    top: -1px;
    z-index: 11;
  }
  
}
.logo {
  display: block;
  width: auto;
  height: var(--headerLogoHeight);
  img {
    display: block;
  }
}
.m {

  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  padding-left: spacer(md);
  align-items: center;
  position: relative;
  
  > div {
    position: relative;
  }
  
  @media screen and (max-width: $x-breakpoint-xs) {
    padding-left: 0;
    padding-right: 0;
  }
  
  &i {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    position: relative;
    padding-left: spacer(base);
    padding-right: spacer(base);
    font-size: .875rem;
    font-weight: 700;
    color: var(--primaryColor);
    height: 44px;

    @media screen and (max-width: $x-breakpoint-xl) {
      min-width: 44px;
    }
    
    @media screen and (max-width: $x-breakpoint-lg) {
      min-width: 44px;
    }
    
    @media screen and (max-width: $x-breakpoint-sm) {
      min-width: 44px;
    }
    
    @media screen and (max-width: $x-breakpoint-xs) {
      padding-left: 0;
      padding-right: 0;
      min-width: 44px;
    }

    svg {
      display: block;
      width: 22px !important;
      height: 18px !important;
    }
    
    > span {
      flex: 0 0 auto;
      padding-left: spacer(xs);
      font-weight: 700;
      color: var(--primaryColor);
    }

  }
  
}

.bbb {
  
  display: flex;
  flex-direction: row;
  gap: spacer(base);
  height: 64px;
  width: 100%;
  align-items: center;
  
  > div {
    flex: 0 0 auto;
    &:first-child {
      flex: 1 1 auto;
      padding-left: spacer(xl);
      @media screen and (max-width: $x-breakpoint-xs) {
        padding-left: 0;
      }
    }
    &:last-child {
      padding-right: spacer(xl);
      @media screen and (max-width: $x-breakpoint-xs) {
        padding-right: spacer(base);
      }
    }
    
    > button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
}

.mm {
  
  display: block;
  
  &l {
    border-bottom: 1px solid var(--lightGrayColor);
    padding-top: spacer(sm);
    padding-bottom: spacer(sm);
  }
  
}