@import '@/components/variables.scss';

.sl {
  
  display: block;
  position: fixed;
  background-color: white;
  color: black;
  transition: 0.5s all ease;
  z-index: 1000;
  max-width: 100% !important;
  @media screen and (max-width: $x-breakpoint-xs) {
    width: 100% !important;
  }

  &RL {
    right: -100%;
    top: 0;
    bottom: 0;
    overflow: hidden;

    @media screen and (max-width: $x-breakpoint-xs) {
      left: 100%;
    }
    @media screen and (min-width: $x-breakpoint-xs) {
      width: auto;
    }
    
    &Show {
      right: 0;
      box-shadow: $x-base-shadow;
      @media screen and (max-width: $x-breakpoint-xs) {
        left: 0;
      }
    }
    
  }
  
}