@import '@/components/variables.scss';

.container {
  display: flex;
  height: 44px;
  flex-direction: row;
  background-color: var(--fieldDisplayBg);
  color: var(--fieldDisplayText);
  border-radius: $x-field-border-radius;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: spacer(xs);
  text-align: left;
  
  label {
    flex: 1 1 auto;
    font-size: .875rem;
    font-weight: 700;
    padding-left: spacer(base);
    line-height: 44px;
  }
  
  span {
    flex: 0 0 auto;
    font-size: 1rem;
    font-weight: 700;
    padding-right: spacer(base);
    color: var(--primaryColor);
  }
  
  > div {
    flex: 0 0 auto;
    padding: 6px;
  }
  
  button {
    border-radius: 6px;
    height: 32px;
    padding-left: 8px;
    padding-right: 8px;
  }
  
}

.primaryFaint {
  background-color: var(--fieldDisplayBg);  
}