@import '@/components/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.raised {
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: $x-base-shadow;
  background-color: #FFFFFF;
  border-radius: $x-tile-border-radius;
  width: 100%;
  overflow: hidden;
}

.plain {
  box-shadow: none;
}

.header {
  flex: 0 0 auto;
  min-height: 64px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;

  > div {
    flex: 0 0 auto;
    position: relative;
  }
  
  &T {
    flex: 1 1 auto !important;
    padding-left: spacer(base);
    padding-right: spacer(base);
    &Lp {
      padding-left: 44px;
    }
    &Rp {
      padding-right: 44px;
    }
    &C {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
  
}

.body {
  flex: 1 1 auto;
  overflow-y: auto;
  position: relative;
  background-color: var(--bodyBg);
  max-width: 100vw;

  form > section {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

}

.thin {

  form {
    display: block;
    padding-left: spacer(xxl);
    padding-right: spacer(xxl);
    @media screen and (max-width: $x-breakpoint-xs) {
      padding-left: 0;
      padding-right: 0;
    }
  }

}

.footer {

  flex: 0 0 auto;
  min-height: 44px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding-top: spacer(base);
  padding-bottom: spacer(base);

  > div {
    flex: 0 0 auto;
    position: relative;
    &:first-child {
      padding-left: spacer(xl);
    }
    &:last-child {
      padding-right: spacer(xl);
    }
  }

}

.white {
  background-color: #FFFFFF;
}

.padded {
  padding: spacer(xl) spacer(xl);
  @media screen and (max-width: $x-breakpoint-xs) {
    padding: spacer(xl);
  }
}

.dropdown {
  padding: 0 0 0 0;
  background-color: #FFFFFF;
  @media screen and (max-width: $x-breakpoint-xs) {
    display: block;
  }
}

.footer {
  flex: 0 0 auto;
  position: relative;
}