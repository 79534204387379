@import '@/components/variables.scss';

.cont {
  
  display: block;
  max-height: 300px;
  overflow: hidden;
  
  @media screen and (min-width: $x-breakpoint-sm) {
    max-height: none;
  }
}

.full {
  @media screen and (max-width: $x-breakpoint-sm) {
    max-height: 0px;
  }
}

.exp {
  
  max-height: 9999999999px;
  
}

.expander {
  @media screen and (min-width: $x-breakpoint-sm) {
    display: none;
  }
}

.btn {
  svg {
    transform: rotate(180deg);
  }
}


