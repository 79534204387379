@import '@/components/variables.scss';

.container {
  
  display: flex;
  flex-direction: row;
  gap: spacer(xxl);
  padding-top: spacer(xxl);
  padding-bottom: spacer(xxl);
  @media screen and (max-width: $x-breakpoint-xs) {
    flex-wrap: wrap;
    gap: spacer(xl);
    padding-top: spacer(xl);
    padding-bottom: spacer(xl);
    }

  > div {
    
    flex: 0 0 auto;
    width: calc(50% - (spacer(xxl)/2));
    
    @media screen and (max-width: $x-breakpoint-xs) {
      width: 100%;
    }
    
  }
  
}

.selector {
  width: 90vw;
  height: 90vh;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: $x-breakpoint-xs) {
    flex-direction: column;
    width: 100vw;
  }
  &-list {
    flex: 0 0 auto;
    width: 420px;
    padding: spacer(xl);
    display: flex;
    flex-direction: column;
    gap: spacer(base);
    overflow-y: auto;
    @media screen and (max-width: $x-breakpoint-xs) {
      flex: 1 1 auto;
      width: 100vw;
    }
  }
  &-item {
    flex: 0;
    border: 1px solid #DDDDDD;
    border-radius: var(--tileBorderRadius);
    display: flex;
    flex-direction: row;
    gap: spacer(base);
    color: #000000;
    &-info {
      flex: 1 1 auto;
      padding: spacer(base) spacer(base) spacer(base) spacer(base);
    }
    &-dealer {
      display: flex;
      flex-direction: row;
      padding-bottom: spacer(xs);
    }
    &-name {
      flex: 1 1 auto;
      font-weight: 700;
    }
    &-line {
      display: block;
    }
  }
  &-map {
    flex: 1 1 auto;
    @media screen and (max-width: $x-breakpoint-xs) {
      display: none;
    }
  }
  &-marker {
    display: block;
    background-color: var(--primaryColor);
    border-radius: 4px;
    padding: 4px;
    border: 0.5px solid var(--primaryText);
  }
}
