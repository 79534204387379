@import '@/components/variables.scss';

.dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  max-width: 100vw;
  background-color: white;
  border-radius: var(--tileBorderRadius);
  top: calc(100% + 1px);
  z-index: 1000;
  box-sizing: border-box;
  transition: all 0.3s ease;
  transform: scale3d(0,0,0);
  overflow: hidden;
  opacity: 0.5;
  
  @media screen and (max-width: $x-breakpoint-xs) {
    transform: none;
    top: auto;
    bottom: -100%;
    left: 0;
    right: 0;
    position: fixed;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: calc(100% - var(--headerBarHeight) - spacer(xl));
  }
  
}

.right {
  right: 0;
  transform-origin: top right;
}
.left {
  left: 0;
  transform-origin: 0 0;
}
.ta {
  transform: none;
  transform-origin: top;
  opacity: 1;
  left: 0;
  right: 0;
  top: calc(100% + 1px);
  @media screen and (max-width: $x-breakpoint-xs) {
    top: calc(100% + 1px);
    bottom: auto;
    position: absolute;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    max-height: 50vh;
    border-bottom-left-radius: var(--tileBorderRadius);
    border-bottom-right-radius: var(--tileBorderRadius);
  }
}

.show {
  transform: scale3d(1,1,1);
  box-shadow: $x-base-shadow;
  opacity: 1;
  @media screen and (max-width: $x-breakpoint-xs) {
    transform: none;
    bottom: 0;
    &.ta {
      height: auto;
      bottom: auto;
    }
  }
}

.bd {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  &_show {
    @media screen and (max-width: $x-breakpoint-xs) {
      display: block;
    }
  }
}

.header {
  flex: 0 0 auto;
}
.body {
  flex: 1 1 auto;
  max-height: calc(100% - 64px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  form {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  }
  @media screen and (max-width: $x-breakpoint-xs) {
    max-height: 100%;
  }
}
