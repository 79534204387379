@import '@/components/variables.scss';

.contents {

  display: block;
  // margin-top: calc((var(--heroHeight) / 2 * -1) - 144px);
  
  @media screen and (max-width: $x-breakpoint-xs) {
    // margin-top: calc((var(--heroHeight) * -1) + 88px);
  }
  
}

.inv {
  display: block;
  padding-top: spacer(xl);
  padding-bottom: spacer(xl);
}

.invflttop {
  background-color: var(--bodyBg);
  box-shadow: $x-base-shadow;
  border-radius: $x-tile-border-radius $x-tile-border-radius 0 0;
  @media screen and (max-width: $x-breakpoint-xs) {
    margin-left: spacer(lg);
    margin-right: spacer(lg);
    width: calc(100vw - 2*spacer(lg))
  }
}