$x-icon-stroke-width: 0.25px;

$x-breakpoint-xl: 1200px;
$x-breakpoint-lg: 1024px;
$x-breakpoint-sm: 768px;
$x-breakpoint-xs: 440px;

$x-base-spacer: var(--spacer);

$x-breakpoints: xl, lg, sm, xs;

$x-spacer-values: (
  base: 1,
  xs: .5,
  sm: .75,
  md: 1.25,
  lg: 1.5,
  xl: 2,
  xxl: 4
);

@function breakpoint($pt) {
  @if $pt == 'xs' {
    @return $x-breakpoint-xs;
  }
  @if $pt == 'sm' {
    @return $x-breakpoint-sm;
  }
  @if $pt == 'lg' {
    @return $x-breakpoint-lg;
  }
  @if $pt == 'xl' {
    @return $x-breakpoint-xl;
  }
};

@function spacer($s) {
  @return calc($x-base-spacer * map-get($x-spacer-values,  #{$s}));
};

$x-base-shadow: 0 0 10px 10px rgba(0,0,0,0.05);

$x-colors: (
  primary: var(--primaryColor),
  primary-faint: var(--primaryColorFaint),
  secondary: var(--secondaryColor),
  black: black,
  white: white,
  gray: #777777,
  gray-faint: #EEEEEE,
  light-gray: #BBBBBB,
  red: var(--redColor)
);

$x-tile-border-radius: var(--tileBorderRadius);
$x-field-border-radius: var(--fieldBorderRadius);

$x-primary-color: var(--primaryColor);
$x-secondary-color: var(--secondaryColor);