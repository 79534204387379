@import '@/components/variables.scss';

.backdrop {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.2);
  z-index: -1;
  opacity: 0;
  transition: 0.5s all ease;
  
  &Show {
    display: block;
    z-index: 99;
    opacity: 1;
  }
}

.modal {
  display: none;
  max-width: 100%;
  background-color: white;
  border-radius: var(--tileBorderRadius);
  box-shadow: $x-base-shadow;
  z-index: -1;
  opacity: 0;
  position: fixed;
  left: 50%;
  top: 50%;
  float: left;
  transform: translate(-50%,-50%);
  transition: 0.5s all ease;
  overflow: hidden;
  
  @media screen and (max-width: $x-breakpoint-xs) {
    transform: none;
    left: 0;
    right: 0;
    border-radius: var(--tileBorderRadius) var(--tileBorderRadius) 0 0;
    top: auto;
    bottom: -100%;
  }
  
  &Show {
    display: block;
    opacity: 1;
    z-index: 100;
    @media screen and (max-width: $x-breakpoint-xs) {
      bottom: 0;
    }
  }
  
}

.padded {
  display: block;
}