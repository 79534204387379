@import '@/components/variables.scss';


.accordian {
  max-width: 100%;
}

.section {
  display: block;
  border-top: 1px solid var(--fieldBorderColor);
  &:last-child {
    border-bottom: 1px solid var(--fieldBorderColor);
  }
}

.header {

  cursor: pointer;
  padding: spacer(lg) 0;
  color: #000000;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;

  > div {
    flex: 0 0 auto;
  }

  &_title {
    flex: 1 1 auto !important;
  }
  
  &_icon {
    transition: all 0.3s ease;
  }

  &_active {
    transform: rotate(180deg);
  }

}

.content {
  display: none;
  &_active {
    display: block;
    padding-bottom: spacer(lg);
  }
}
