@import '@/components/variables.scss';

$x-inv-tile-spacer: spacer(lg);

.tiles {
  
  display: block;
  padding: 0 0;
  
  &Padded {
    padding: 0 $x-inv-tile-spacer;
  }
  
  &Inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $x-inv-tile-spacer;
    width: 100%;
    justify-content: center;
  }
  
  &Column {
    flex-direction: column;
  }
  
  @media screen and (max-width: $x-breakpoint-xs) {
    padding: 0 0;
  }
  
}

.tile {
  
  flex: 0 0 auto;
  width: calc((100% - (4 * $x-inv-tile-spacer)) / 5);
  min-width: 280px;
  max-width: 100%;
  background-color: var(--tileBackground);
  // box-shadow: 0 0 0 1px rgba(0,0,0,0.02);
  border-radius: $x-tile-border-radius;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  color: black;
  
  a {
    color: black;
  }

  @media screen and (max-width: $x-breakpoint-xl) {
    width: calc((100% - (3 * $x-inv-tile-spacer)) / 4);
  }
  
  @media screen and (max-width: $x-breakpoint-lg) {
    width: calc((100% - (2 * $x-inv-tile-spacer)) / 3);
  }
  
  @media screen and (max-width: $x-breakpoint-sm) {
    width: calc((100% - (1 * $x-inv-tile-spacer)) / 2);
  }
  
  @media screen and (max-width: $x-breakpoint-xs) {
    width: 100%;
    min-width: 0;
    min-height: 400px;
  }
  
}

.invisible {
  background-color: transparent;
  border-radius: 0;
  overflow: hidden;
  height: 0;
}

.xlTiles {
  gap: 44px;
}

.xl {
  
  min-width: 320px;
  
}
  
.photo {
  flex: 0 0 auto;
  width: 100%;
  padding: calc((100% * var(--invPhotoAspectRatio, 0.75)) / 2);
  position: relative;
  border-radius: $x-tile-border-radius;
  overflow: hidden;
  img {
    background-color: white;
  }
  &-flipped {
    &-h {
      img {
        transform: rotateY(180deg);
      }
    }
  }
}
  
.info {
    
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding-left: $x-inv-tile-spacer;
  padding-right: $x-inv-tile-spacer;
  
  > div {
    
    border-top: 1px solid #DDDDDD;
    padding-top: $x-inv-tile-spacer;
    padding-bottom: $x-inv-tile-spacer;
  
    &:last-child {
      flex: 0 0 auto;
    }

    &:first-child {
      flex: 1 1 auto;
    }
    
    > div {
      display: flex;
      flex-direction: row;
      padding-bottom: spacer(xs);
      &:last-child {
        padding-bottom: 0;
      }
    }
    
  }
  
}

.topbtns {
  display: flex;
  flex-direction: row;
  gap: spacer(xs);
  position: absolute;
  top: spacer(base);
  right: spacer(base);
}
