@import '@/components/variables.scss';

.g {
  
  display: block;
  width: 100%;
  position: relative;
  
  &M {
    
    width: 100%;
    padding: calc(var(--vdpPhotoAspectRatio, 0.75) / 2 * 100%);
    position: relative;
    border-radius: $x-tile-border-radius;
    overflow: hidden;
    background-color: rgba(0,0,0,0.5);
  
    // @media screen and (max-width: $x-breakpoint-lg) {
    //   border-radius: 0;
    // }
    
    @media screen and (max-width: $x-breakpoint-sm) {
      border-radius: 0;
    }
    
    @media screen and (max-width: $x-breakpoint-xs) {
      border-radius: 0;
    }      
    
  }

  &fliph {
    transform: rotateY(180deg);
  }

  &arr {
    display: flex;
    opacity: 0;
    transition: all 0.5s ease;
    position: absolute;
    top: 50%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    margin-top: -22px;
    background-color: rgba(0,0,0,0.4);
    color: white;
    border-radius: 100%;
    z-index: 2;
    &s {
      opacity: 1;
    }
    svg {
      width: 20px;
      height: 20px;
    }
    &:first-of-type {
      left: spacer(base);
      svg {
        margin-left: -4px;
      }
    }
    &:last-of-type {
      right: spacer(base);
      svg {
        margin-left: 3px;
      }
    }
  }
  
}

.vt {
  
  &Th {
    flex: 0 0 auto;
    width: 112px;
    height: 82px;
    position: relative;
    box-sizing: border-box;
    border-radius: $x-field-border-radius;
    overflow: hidden;

    &Sel {
      box-shadow: 0 0 0 4px var(--secondaryColor);
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 0;
        background-color: var(--secondaryColor);
      }
    }
  }

  &Sr {
    
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: spacer(xs);
    box-sizing: border-box;
    margin-top: spacer(md);
    overflow-y: auto;
    padding: 4px;
    z-index: 1;
    &::-webkit-scrollbar {
      display: none;
    }
  
    @media screen and (max-width: $x-breakpoint-sm) {
      width: 100%;
      border-radius: 0;
      margin-top: 0;
      padding: spacer(xs);
    }
    
  }
 
}