@import '@/components/variables.scss';

.form {
  
  display: block;
  max-width: calc(100vw - spacer(xl));
  position: relative;

  @media screen and (max-width: $x-breakpoint-xs) {
    max-width: 100vw;
  }

  input[type=text], input[type=password], input[type=number], select, textarea {
  
    display: block;
    appearance: none;
    background-color: var(--fieldBg);
    border: var(--fieldBorderWidth) solid var(--fieldBorderColor);
    color: var(--fieldText);
    box-shadow: none;
    outline: none;
    height: 44px;
    border-radius: $x-field-border-radius;
    width: 100%;
    padding-left: spacer(base);
    
    &:focus {
      border-color: var(--fieldOutlineColor) !important;
    }
    
    @media screen and (max-width: $x-breakpoint-xs) {
      font-size: 16px;
    }
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
    
  input[type="range"] {
    -webkit-appearance: none;
    margin-right: 15px;
    width: 100%;
    height: 3px;
    background: #DDDDDD;
    border-radius: 3px;
    background-image: linear-gradient(var(--primaryColor), var(--primaryColor));
    background-size: 70% 100%;
    background-repeat: no-repeat;
  }
  
  [dir="rtl"] input[type="range"] {
    background: var(--primaryColor);
    background-image: linear-gradient(#fff, #fff);
    background-size: 30% 100%;
    background-repeat: no-repeat;
  }
  
  /* Input Thumb */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: var(--primaryColor);
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 #555;
    transition: background .3s ease-in-out;
  }
  
  input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: var(--primaryColor);
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 #555;
    transition: background .3s ease-in-out;
  }
  
  input[type="range"]::-ms-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: var(--primaryColor);
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 #555;
    transition: background .3s ease-in-out;
  }
  
  input[type="range"]::-webkit-slider-thumb:hover {
    background: var(--primaryColor);
  }
  
  input[type="range"]::-moz-range-thumb:hover {
    background: var(--primaryColor);
  }
  
  input[type="range"]::-ms-thumb:hover {
    background: var(--primaryColor);
  }
  
  /* Input Track */
  input[type=range]::-webkit-slider-runnable-track  {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
  
  input[type=range]::-moz-range-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
  
  input[type="range"]::-ms-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
  
  select {
    background-image: var(--dropdownArrow);
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    background-size: 16px 16px;
  }

  textarea {
    min-height: 72px;
    resize: none;
    padding-top: spacer(base);
    padding-bottom: spacer(base);
  }
  
}

.grp {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  min-width: 120px;
  align-items: center;
  > div {
    width: 100%;
  }
}

.cells {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: spacer(xl);
  @media screen and (max-width: $x-breakpoint-xs) {
    gap: spacer(lg);
  }
  > div {
    flex: 0 0 auto;
  }
}

.label {
  display: block;
  color: var(--primaryColor);
  font-size: .875rem;
  padding-bottom: spacer(xs);
}

.cell {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  &50 {
    width: calc(50% - (spacer(xl) / 2));
    @media screen and (max-width: $x-breakpoint-xs) {
      width: 100%;
    }
  }
}

.inline {
  > label {
    flex: 0 0 auto;
    padding-right: spacer(base);
  }
  > div {
    flex: 1 1 auto;
  }
}

.trn {
  input, select, textarea {
    border: none;
    background-color: transparent;
  }
}

.legend {
  display: flex;
  flex-direction: row;
  font-size: .675rem;
  color: black;
  padding-top: spacer(xs);
  > div {
    flex: 0 0 auto;
    &:first-child {
      flex: 1 1 auto;
    }
  }
}

.padded {
  padding: spacer(xl)
}